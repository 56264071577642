<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white"
      :class="{
        'login-signin-on': this.state == 'signin',
        'login-signup-on': this.state == 'signup',
        'login-forgot-on': this.state == 'forgot',
      }"
      id="kt_login"
    >
      <!--begin::Aside-->
      <div class="login-aside d-flex flex-column flex-row-auto bg-base">
        <!-- <ImageComponent place="login-white"></ImageComponent> -->
        <div
          class="d-flex flex-column-auto flex-column pt-lg-20 pt-15"
          style="background-color: #e0e2e2"
        >
          <a href="#" class="text-center mb-3">
            <img
              src="/media/illustration/Logo-Transparant-Only-Text-01.png"
              class="max-h-70px"
              alt=""
            />
          </a>
          <!-- <h5
            class="font-weight-light text-center font-size-h4 font-size-h6-lg text-white mb-6"
          >
          Lorem ipsum dolor sit amet consectetur adipisicing elit.
          </h5> -->
        </div>
        <div
          class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
          ref="cover"
          :style="{
            backgroundImage: `url(${background_image})`,
            backgroundSize: 'cover',
            backgroundColor: '#E0E2E2',
          }"
        ></div>
      </div>
      <!--begin::Aside-->
      <!--begin::Content-->
      <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->
          <div class="login-form login-signin">
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_signin_form"
              @submit.stop.prevent="checkUserLogin()"
            >
              <!-- <ImageComponent place="login"></ImageComponent> -->
              <div class="pb-13 pt-lg-0 pt-5 d-flex justify-content-center">
                <img :src="setting.logo" width="280" max-height="150" alt="" />
              </div>
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark"
                  >Email
                </label>
                <div
                  id="example-input-group-1"
                  label=""
                  label-for="example-input-1"
                >
                  <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    type="text"
                    name="email"
                    ref="email"
                    v-model="form.email"
                  />
                </div>
              </div>
              <div class="form-group">
                <!-- <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                    >Password</label
                  >
                  <a
                    class="font-size-h6 font-weight-bolder pt-5 text-base-orange"
                    id="kt_login_forgot"
                    @click="showForm('forgot')"
                    >Lupa Password ?</a
                  >
                </div> -->
                <div
                  id="example-input-group-2"
                  label=""
                  label-for="example-input-2"
                >
                  <div class="input-group mb-2">
                    <input
                      class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                      :type="passwordIsVisible"
                      name="password"
                      ref="password"
                      v-model="form.password"
                      autocomplete="off"
                    />
                    <div
                      class="input-group-prepend"
                      @click="
                        passwordIsVisible == 'password'
                          ? (passwordIsVisible = 'text')
                          : (passwordIsVisible = 'password')
                      "
                    >
                      <div
                        class="input-group-text"
                        style="border: none; cursor: pointer"
                      >
                        <template v-if="passwordIsVisible == 'password'">
                          <b-icon-eye-slash></b-icon-eye-slash>
                        </template>
                        <template v-if="passwordIsVisible == 'text'">
                          <b-icon-eye></b-icon-eye>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pb-lg-0 pb-5">
                <button
                  ref="kt_login_signin_submit"
                  class="btn font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3 text-white bg-base"
                >
                  Masuk
                </button>
                <!-- <button
                  type="button"
                  class="btn btn-light-primary font-weight-bolder px-8 py-4 my-3 font-size-lg"
                >
                  <span class="svg-icon svg-icon-md">
                    <inline-svg
                      src="media/svg/social-icons/google.svg"
                    /> </span
                  >Sign in with Google
                </button> -->
              </div>
            </form>
          </div>
          <!--end::Signin-->
          <!--begin::Signup-->
          <div class="login-form login-signup">
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_signup_form"
              @submit.stop.prevent="onSubmitRegister()"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  Sign Up
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  Enter your details to create your account
                </p>
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="text"
                  placeholder="Fullname"
                  name="fullname"
                  ref="fullname"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="email"
                  placeholder="Email"
                  name="email"
                  ref="remail"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="password"
                  placeholder="Password"
                  name="password"
                  ref="rpassword"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="password"
                  placeholder="Confirm password"
                  name="cpassword"
                  ref="cpassword"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <label class="checkbox mb-0">
                  <input type="checkbox" name="agree" />
                  <span class="mr-2"></span>
                  I Agree the
                  <a href="#" class="ml-2">terms and conditions</a>.
                </label>
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0 pb-3">
                <button
                  ref="kt_login_signup_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                  style="width: 150px"
                >
                  Submit
                </button>
                <button
                  type="button"
                  id="kt_login_signup_cancel"
                  class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                  @click="showForm('signin')"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
          <!--end::Signup-->
          <!--begin::Forgot-->
          <div class="login-form login-forgot">
            <!--begin::Form-->
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_forgot_form"
              ref="kt_login_forgot_form"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  Lupa Password ?
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  Masukan email anda untuk mereset password
                </p>
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="email"
                  placeholder="Email"
                  name="email"
                  autocomplete="off"
                  v-model="reset.email"
                />
              </div>
              <span class="text-danger">{{ error.email }}</span>
              <div class="form-group d-flex flex-wrap pb-lg-0">
                <button
                  @click="forgetPassword"
                  type="button"
                  id="kt_login_forgot_submit"
                  class="btn font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4 text-white bg-base"
                >
                  Kirim
                </button>
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                  @click="showForm('signin')"
                >
                  Batal
                </button>
              </div>
            </form>
          </div>
          <!--end::Forgot-->
        </div>
        <!--begin::Content footer-->
        <!-- class="d-flex justify-content-lg-start justify-content-center align-items-end py-7 py-lg-0" -->
        <div class="d-flex justify-content-center py-7 py-lg-0 text-center">
          <p class="font-size-h6">
            © {{ year }} Medhop Software. All Rights Reserved. <br />
            Powered By Wellmagic Media Digital.
          </p>
          <!-- <p>Powered by Wellmagic Media Digital.</p> -->
          <!-- <a href="#" class="text-dark font-weight-bolder font-size-h5"
            >Terms</a
          >
          <a href="#" class="text-dark ml-10 font-weight-bolder font-size-h5"
            >Plans</a
          > -->
          <!-- <a href="#" class="text-dark font-weight-bolder font-size-h5"
            >Hubungi Kami</a
          > -->
        </div>
        <!--end::Content footer-->
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import ApiService from "@/core/services/api.service.js";
import JwtService from "@/core/services/jwt.service";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import { LOGIN, LOGOUT, REGISTER } from "@/core/services/store/auth.module";
import ImageComponent from "@/component/general/ImageComponent.vue";
import Swal from "sweetalert2";
import moment from "moment";

import myCaptcha from "@/component/general/MyCaptcha.vue";

import module from "@/core/modules/CrudModule.js";
import moduleSaas from "@/core/modules/CrudSaasModule.js";
import validation from "@/core/modules/ValidationModule.js";
import axios from "axios";
import {
  getConfiguration,
  setConfiguration,
  getDbConfig,
} from "@/core/services/jwt.service.js";
import {
  UPDATE_SS_CLIENT_ID,
  UPDATE_SS_CLIENT_SECRET,
  UPDATE_SS_ORGANIZATION_ID,
  UPDATE_SS_ACCESS_TOKEN,
} from "@/core/services/store/tokenstore.module";
import {
  setTokenBridge,
  setTokenSatuSehat,
  setRefreshTokenBridge,
  setSsOrganizationId,
  setSsLocationDefault,
  setSsSecret,
  setSsId,
} from "@/core/services/jwt.service.js";

export default {
  name: "login-1",

  data() {
    return {
      state: "signin",
      form: {
        email: "",
        password: "",
      },
      reset: {
        email: "",
        subdomain: "",
      },
      error: {
        email: "",
      },
      passwordIsVisible: "password",
      year: moment().format("YYYY"),
      background_image: getConfiguration() != null ? "" : "",
      dataLoaded: false,
      dbConfig: getDbConfig(),
      dbConfigParams: "",
      setting: {},
    };
  },

  components: {
    ImageComponent,
    myCaptcha,
  },

  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    ...mapGetters(["currentUser"]),
  },

  mounted() {
    // if (this.background_image == "") {
    //   this.getSetting();
    // }else{
    //   this.getSetting();
    // }
    this.getDbConfiguration();

    const signin_form = KTUtil.getById("kt_login_signin_form");
    const signup_form = KTUtil.getById("kt_login_signup_form");
    const forgot_form = KTUtil.getById("kt_login_forgot_form");

    this.fv = formValidation(signin_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: "Username is required",
            },
          },
        },
        password: {
          validators: {
            notEmpty: {
              message: "Password is required",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    this.fv1 = formValidation(signup_form, {
      fields: {
        fullname: {
          validators: {
            notEmpty: {
              message: "Full name is required",
            },
          },
        },
        email: {
          validators: {
            notEmpty: {
              message: "Email is required",
            },
            emailAddress: {
              message: "The value is not a valid email address",
            },
          },
        },
        password: {
          validators: {
            notEmpty: {
              message: "Password is required",
            },
          },
        },
        cpassword: {
          validators: {
            notEmpty: {
              message: "Confirm password is required",
            },
            identical: {
              compare: function () {
                return signup_form.querySelector('[name="password"]').value;
              },
              message: "The password and its confirm are not the same",
            },
          },
        },
        agree: {
          validators: {
            notEmpty: {
              message: "You should agree terms and conditions",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    this.fv2 = formValidation(forgot_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: "Email wajib diisi",
            },
            emailAddress: {
              message: "Format Email tidak sesuai, periksa kembali email anda",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });
  },

  methods: {
    showForm(form) {
      this.state = form;
      var form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(
        KTUtil.getById(form_name),
        "animate__animated animate__backInUp"
      );
    },

    checkUserLogin() {
      ApiService.post("check-user-login", this.form)
        .then((response) => {
          if (response.data.status == true) {
            this.onSubmitLogin();
          } else if (response.data.status == 404) {
            Swal.fire({
              title: "Gagal",
              text: "Akun tidak terdaftar",
              icon: "error",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          } else {
            Swal.fire({
              title: "Gagal",
              text: "User yang login sudah mencapai batas maksimal",
              icon: "error",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        })
        .catch((error) => {
        });
    },

    async forgetPassword() {
      this.error.email = "";

      let dataJson = JSON.parse(localStorage.getItem("tenantconfig"));

      this.reset.subdomain = dataJson.domain;

      let response = await module.submit(this.reset, "/reset-password");
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(
          response.success.title,
          response.success.message,
          "success"
        ).then((result) => {
          this.showForm("signin");
        });
      }
    },

    onSubmitLogin() {
      // this.fv.validate();
      SwalLoading.fire();

      // this.fv.on("core.form.valid", () => {
      var email = this.form.email;
      var password = this.form.password;

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      // const submitButton = this.$refs["kt_login_signin_submit"];
      // submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        // send login request
        this.$store
          .dispatch(LOGIN, { email, password })
          // go to which page after successfully login
          .then(() => {
            let access_right, position_right;
            ApiService.get("users/me").then((data) => {
              ApiService.get("users/" + data.data.data.user_id).then(
                (response) => {
                  if (data.data.data.position_id) {
                    ApiService.get(
                      "positions/" + data.data.data.position_id
                    ).then((result) => {
                      access_right = response.data.meta.access_right_display;
                      position_right = result.data.meta.access_right_display;

                      let merge = [...access_right, ...position_right];

                      window.localStorage.setItem(
                        "access_right_display",
                        JSON.stringify(merge)
                      );

                      let user = data.data.data;
                      // user.access_right = access_right + ", " + position_right;
                      JwtService.setUser(JSON.stringify(user));

                      this.$router.push({ name: "dashboard" });
                      SwalLoading.close();
                    });
                  } else if (!data.data.data.position_id) {
                    window.localStorage.setItem(
                      "access_right",
                      response.data.meta.access_right
                    );

                    window.localStorage.setItem(
                      "access_right_display",
                      JSON.stringify(response.data.meta.access_right_display)
                    );

                    let user = data.data.data;
                    access_right = response.data.meta.access_right;
                    user.access_right = response.data.meta.access_right;
                    JwtService.setUser(JSON.stringify(user));

                    this.$root.$emit("setMenuActive");

                    // get data subscribe
                    // let strg = JSON.parse(localStorage.getItem("tenantconfig"));
                    // let subs_end = strg.end_date;

                    // let current_date = new Date().toISOString().split("T")[0];

                    for (let z = 0; z < 20; z++) {
                      if (z == 19) {
                        // if (subs_end < current_date) {
                        //   let url = window.location.href;
                        //   url = url.split("/");

                        //     Swal.fire({
                        //       icon: "warning",
                        //       title: "Perhatian",
                        //       text: "Masa aktif anda telah habis, silahkan lakukan perpanjangan",
                        //       timerProgressBar: true,
                        //       timer: 5000,
                        //       showConfirmButton: false,
                        //     }).then(() => {
                        //       router
                        //         .push({
                        //           path: "/subscribe/package",
                        //         })
                        //         .catch(() => true);
                        //     });

                        // } else {
                        this.$router.push({ name: "dashboard" });
                        SwalLoading.close();
                        // }
                      }
                    }
                  }
                }
              );
            });

            // this.$router.push({ name: "dashboard" });
            // SwalLoading.close();
            this.getSetting();
          });

        // submitButton.classList.remove(
        //   "spinner",
        //   "spinner-light",
        //   "spinner-right"
        // );
      }, 100);
      // });

      // this.fv.on("core.form.invalid", () => {
      //   Swal.fire({
      //     title: "",
      //     text: "Please, provide correct data!",
      //     icon: "error",
      //     confirmButtonClass: "btn btn-secondary",
      //     heightAuto: false,
      //   });
      // });
    },

    onSubmitRegister() {
      this.fv1.validate();

      this.fv1.on("core.form.valid", () => {
        const email = this.$refs.remail.value;
        const password = this.$refs.rpassword.value;

        // clear existing errors
        this.$store.dispatch(LOGOUT);

        // set spinner to submit button
        const submitButton = this.$refs["kt_login_signup_submit"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // dummy delay
        setTimeout(() => {
          // send register request
          this.$store
            .dispatch(REGISTER, {
              email: email,
              password: password,
            })
            .then(() => this.$router.push({ name: "dashboard" }));

          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        }, 2000);
      });

      this.fv1.on("core.form.invalid", () => {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
      });
    },

    async getDbConfiguration() {
      let url = "";
      if (process.env.NODE_ENV === "production") {
        // HOST
        url = window.location.href;
      } else {
        // Local
        // url = "https://mitrahub2.medhop.id";
        // url = "https://sehati2.medhop.id";
        // url = "https://melati.medhop.id";
        // url = "https://demo.medhop.id/";
        // url = "https://rzen.medhop.id";
        // url = "https://sehati2.medhop.id";
        // url = "https://klinikkita2.medhop.id";
        // url = "https://sembuh.medhop.id";
        // url = "https://apoteksehati.medhop.id";
        // url = "https://baru1.medhop.id";
        url = "https://indonesiasehat.medhop.id";
        // url = "https://indonesiakuat.medhop.id";
      }

      let domain = new URL(url);
      domain = domain.hostname;
      let response = await moduleSaas.getDbConfig(
        `get-db-config?domain=${domain}`
      );

      if (response != null) {
        let dbconfig = response;
        localStorage.setItem("tenantconfig", JSON.stringify(response));
        ApiService.setDbConfigHeader(dbconfig);
        this.getSetting();
      } else {
        this.$router.push("/404");
      }
    },

    async getSetting() {
      let response = await module.get("settings-no-auth/1");
      if (response != null) {
        this.dataLoaded = true;
        this.background_image = response.cover_image;
        this.$refs.cover.style.backgroundImage = `url('${response.cover_image}')`;
        this.setting = response;
        setConfiguration(JSON.stringify(response));
        this.getSSAccessToken();
      }
    },

    async getSSAccessToken() {
      // Get Token
      let login_data = {
        username: "medhop-api-integration@medhop.id",
        password: "wm547u53h47",
        grant_type: "password",
        scope: "*",
        client_id: JwtService.getConfiguration().client_id,
        client_secret: JwtService.getConfiguration().client_secret,
      };
      ApiService.post("oauth/token", login_data)
        .then(({ data }) => {
          // let response = await module.get("satu-sehat-settings/1");
          // if (response != null) {
          //   console.log("ssaccess token", response);
          // }
          ApiService.setHeader(data.access_token);
          ApiService.get("satu-sehat-settings/1").then((response) => {
            let access_token = response.data.data;
            this.$store.dispatch(UPDATE_SS_CLIENT_ID, access_token.client_id);
            this.$store.dispatch(
              UPDATE_SS_CLIENT_SECRET,
              access_token.client_secret
            );
            this.$store.dispatch(
              UPDATE_SS_ORGANIZATION_ID,
              access_token.organization_id
            );

            setSsSecret(access_token.client_secret);
            setSsId(access_token.client_id);

            // Login To API Bridge & Satu Sehat
            let login_data_api_bridge = {
              username: "medhop@wellmagic.id",
              password: "wm547u53h47",
              grant_type: "password",
              scope: "*",
              ss_client_id: access_token.client_id,
              ss_client_secret: access_token.client_secret,
              satu_sehat_clinic_id: JwtService.getConfiguration().satu_sehat_clinic_id,
            };

            ApiService.bridge
              .post("oauth/token", login_data_api_bridge)
              .then((response) => {
                let data = response.data;
                setTokenBridge(data.bridge.access_token);
                setRefreshTokenBridge(data.bridge.refresh_token);
                setTokenSatuSehat(data.satusehat.access_token);
                setSsOrganizationId(access_token.organization_id);
                setSsLocationDefault(access_token.location_default);
                this.$store.dispatch(
                  UPDATE_SS_ACCESS_TOKEN,
                  data.satusehat.access_token
                );
                ApiService.setHeaderBridge(data.bridge.access_token);
              });
          });
        })
        .catch(({ data }) => {
        });
    },
  },
};
</script>

<style scoped>
.color-sample {
  color: rgb(242, 242, 243);
}

.cover-image-width {
  width: 100%;
}

.bg-base {
  background-color: #305b8f !important;
  color: white !important;
}
.text-base-orange {
  color: #f47b20 !important;
}
</style>